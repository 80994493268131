const initialState = {
  productos: [],
  idProductoArray: 1,
};

const productosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AGREGAR_PRODUCTO":
      // Creamos una copia del estado actual
      const newState = { ...state };
      // Agregamos el producto al arreglo de productos
      newState.productos = [...newState.productos, action.payload];
      // Incrementamos el idProductoArray en 1
      newState.idProductoArray += 1;
      return newState;
    case "BORRAR_PRODUCTOS":
      return {
        ...state,
        productos: [], // Borra todos los despachos estableciendo un arreglo vacío
        idProductoArray: 1, // Reinicia el idProductoArray a 1 al borrar todos los productos
      };
    case "ACTUALIZAR_PRODUCTO":
      return {
        ...state,
        productos: action.payload, // Actualiza el estado con la nueva copia de despachos
      };
    case "ACTUALIZAR_ID_PRODUCTO_ARRAY": // Nueva acción para actualizar idProductoArray
      return {
        ...state,
        idProductoArray: action.payload, // Actualiza el estado con el nuevo valor de idProductoArray
      };
    case "REINICIAR_PRODUCTOS":
      return {
        ...state,
        productos: [],
      };
    default:
      return state;
  }
};

export default productosReducer;
