import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  useMediaQuery,
  Modal,
  Chip,
  List,
  ListItem,
} from "@mui/material";
import MenuSection from "./MenuSection";
import DeleteIcon from "@mui/icons-material/Delete";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PaymentIcon from "@mui/icons-material/Payment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { FiAlertCircle } from "react-icons/fi";
import estadoDespacho from "../api/estadoDespacho";
import percibePercepciones from "../api/percibePerceciones";
import generarFacturaJson from "../helpers/generarFacturaJson";
import { validateCUITCUIL, formatCuit, handleCuitChange } from "../helpers/useCuit";
import { calcularTotal, combinarArticulos, limpiarDespachos } from "../helpers/useArticulos"; // Importando las funciones desde useArticulos.js
import { handleDeleteRow as deleteRowHelper } from "../helpers/useArticulos"; // Renombrar la función al importarla
import { generarFacturaInfo } from "../helpers/generarFacturaInfo";
import numeroComprobante from "../api/numeroComprobante";

const FacturacionFormMini = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [total, setTotal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cuit, setCuit] = useState("");
  const [cuitError, setCuitError] = useState(false);
  const [isEmitirFacturaEnabled, setIsEmitirFacturaEnabled] = useState(false);
  const [isPagosButtonDisabled, setIsPagosButtonDisabled] = useState(true);
  const [isSuspenderRemitoEnabled, setIsSuspenderRemitoEnabled] = useState(true);
  const [clientePercepciones, setClientePercepciones] = useState(0);
  const [cliente, setCliente] = useState(null);
  const [condicion, setCondicion] = useState("CONTADO");
  const [fechaHora, setFechaHora] = useState(new Date());
  const [comprobante, setComprobante] = useState(null); // Inicializa con null para indicar que aún no está cargado
  const [loadingComprobante, setLoadingComprobante] = useState(true); // Estado para manejar la carga asíncrona
  const productos = useSelector(state => state.productos.productos);
  const totalProductos = [...productos];
  const limiteAfip = parseFloat(localStorage.getItem("limite"));

  useEffect(() => {
    const clienteStr = localStorage.getItem("cliente");
    if (clienteStr) {
      const cliente = JSON.parse(clienteStr);
      setCliente(cliente);
      localStorage.setItem("emiteFacturayRemito", cliente.emiteFacturayRemito);
    }
  }, []);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setFechaHora(new Date());
    }, 1000);

    return () => clearInterval(intervalo);
  }, []);

  useEffect(() => {
    if (cliente !== null) {
      const tieneProductosConImportePositivo = totalProductos.some(producto => producto.importe > 0);
      if (cliente.condicionVenta.condicionVentaContado === false) {
        setCondicion("CTA. CTE.");
        setIsEmitirFacturaEnabled(tieneProductosConImportePositivo);
      } else if (cliente.condicionVenta.condicionVentaContado === true) {
        setCondicion("CONTADO");
        setIsEmitirFacturaEnabled(false);
      }
    } else {
      if (totalProductos.some(producto => producto.importe > 0)) {
        setIsPagosButtonDisabled(false);
      }
    }
  }, [cliente, totalProductos]);

  useEffect(() => {
    const totalCalculado = calcularTotal(totalProductos);

    if (totalCalculado !== total) {
      setTotal(totalCalculado);
      localStorage.setItem("totalProductos", totalCalculado);
    }
  }, [totalProductos]); // Solo se ejecuta cuando cambian los productos

  useEffect(() => {
    const updateFacturaInfo = async () => {
      const despachos = [];
      const articulos = [];

      totalProductos.forEach(producto => {
        if (producto.idDespacho) {
          despachos.push(producto);
        } else {
          articulos.push(producto);
        }
      });

      const articulosCombinados = combinarArticulos(articulos);

      const facturaInfo = {
        despachos: despachos.length > 0 ? despachos : null,
        articulos: articulosCombinados.length > 0 ? articulosCombinados : null,
        cliente: cliente || null,
        total: total,
      };

      const puesto = JSON.parse(localStorage.getItem("puesto"));
      const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");

      const facturaJsonLocal = generarFacturaJson(null, puesto, cliente, facturaInfo, parseFloat(total), null, emiteFacturayRemito);

      console.log(facturaJsonLocal);
      console.log(localStorage.getItem("idTalonarioA"));
      console.log(localStorage.getItem("idTalonarioB"));
      console.log(localStorage.getItem("idTalonarioREM"));

      try {
        let nuevoComprobante;
        if (facturaJsonLocal.cabecera.TipoComprobante.toLowerCase() === "Remito".toLowerCase()) {
          nuevoComprobante = await numeroComprobante.getNumeroComprobante(localStorage.getItem("idTalonarioREM"));
        } else if (facturaJsonLocal.cabecera.LetraComprobante === "A") {
          nuevoComprobante = await numeroComprobante.getNumeroComprobante(localStorage.getItem("idTalonarioA"));
        } else {
          nuevoComprobante = await numeroComprobante.getNumeroComprobante(localStorage.getItem("idTalonarioB"));
        }

        setComprobante(nuevoComprobante.data);
      } catch (error) {
        console.error("Error obteniendo el nuevo número de comprobante:", error);
      } finally {
        setLoadingComprobante(false); // Indica que la carga ha terminado
      }

      if (cliente && localStorage.getItem("percepciones") === "true") {
        try {
          const percepciones = await percibePercepciones.getPercibePercepciones(facturaJsonLocal);

          if (typeof percepciones === "number") {
            const totalConPercepciones = parseFloat(total) + percepciones;

            if (totalConPercepciones.toFixed(2) !== total) {
              setClientePercepciones(percepciones.toFixed(2));
              setTotal(totalConPercepciones.toFixed(2));
              localStorage.setItem("percibePercepciones", percepciones.toFixed(2));
              localStorage.setItem("totalProductos", totalConPercepciones.toFixed(2));
            }
          } else {
            console.error("Las percepciones no son un número:", percepciones);
          }
        } catch (error) {
          console.error("Error obteniendo percepciones:", error);
        }
      }
    };

    updateFacturaInfo();
  }, [total, cliente]); // Este efecto solo se ejecuta cuando cambian el total o el cliente

  const handleDeleteRow = async id => {
    await deleteRowHelper(id, totalProductos, dispatch, estadoDespacho);
  };

  const handleDeleteAll = () => {
    setTotal(0);
    dispatch({ type: "BORRAR_PRODUCTOS" });
    setCondicion("CONTADO");
    setIsEmitirFacturaEnabled(false);
    setIsPagosButtonDisabled(true);
    localStorage.setItem("cliente", "");
    limpiarDespachos(estadoDespacho, JSON.parse(localStorage.getItem("despachosSeleccionados")) || []);
  };

  const handleFacturaElectronica = () => {
    const facturaInfo = generarFacturaInfo(productos, cliente);
    localStorage.setItem("facturaInfo", JSON.stringify(facturaInfo));
    navigate("/firma/");
  };

  const handleSuspenderCTACTE = () => {
    setIsPagosButtonDisabled(false);
    setIsEmitirFacturaEnabled(true);
    setCondicion("CONTADO");
    cliente.condicionVenta.condicionVentaContado = true;
    cliente.condicionVenta.descripcionCondicionVenta = "CONTADO";
    cliente.emiteRemito = false;
    localStorage.setItem("cliente", JSON.stringify(cliente));
    localStorage.setItem("condicionVenta", "CONTADO");
  };

  const handleSuspenderRemito = () => {
    setIsSuspenderRemitoEnabled(false);
    cliente.emiteFacturayRemito = false;
    cliente.emiteRemito = false;
    localStorage.setItem("cliente", JSON.stringify(cliente));
    localStorage.setItem("condicionVenta", cliente.condicionVenta.condicionVentaContado ? "CONTADO" : "CTA. CTE.");
  };

  const smallFieldStyle = {
    "& .MuiInputLabel-root": {
      fontSize: window.innerWidth > 1000 ? "16px" : "12px",
    },
    "& .MuiInputBase-input": {
      fontSize: window.innerWidth > 1000 ? "16px" : "12px",
      marginRight: "9px",
    },
  };

  const renderTextField = (label, fullWidth = true, marginRight = 0, value = "") => (
    <TextField disabled label={label} fullWidth={fullWidth} value={value} sx={{ ...smallFieldStyle, marginRight }} />
  );

  const renderSelectField = (label, options = [], marginRight = 0) => (
    <FormControl fullWidth sx={{ ...smallFieldStyle, marginRight }}>
      <InputLabel>{label}</InputLabel>
      <Select defaultValue="">
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <Box
        sx={{
          marginRight: "100px",
          width: "83%",
          backgroundColor: "white",
          boxSizing: "border-box",
          marginBottom: window.innerWidth > 1000 ? "0%" : "5%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              minWidth: "120px",
              backgroundColor: theme.bandera.colorPrimario,
              color: "white",
              fontWeight: "bold",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
            }}
          >
            Número
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              border: "1px solid #ccc",
              borderRadius: "4px",
              height: "40px",
              marginLeft: "15px",
              padding: "0 10px",
            }}
          >
            {loadingComprobante ? ( // Mientras está cargando, mostramos un mensaje de carga
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555" }}>
                Cargando...
              </Typography>
            ) : (
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555" }}>
                {comprobante ? comprobante.nuevoNumeroComprobante : "No disponible"}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              minWidth: "150px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "0 10px",
              marginLeft: "15px",
            }}
          >
            <Typography variant="body1" sx={{ color: "#555" }}>
              <InputLabel sx={{ fontSize: "9px" }}>Fecha y hora de emisión </InputLabel>
              {fechaHora.toLocaleDateString()} - {fechaHora.toLocaleTimeString()}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: "10px" }}>
          <TextField
            disabled
            label="Cliente"
            value={cliente ? cliente.razonSocial : ""}
            fullWidth
            sx={{ ...smallFieldStyle, marginRight: "12px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled
            label="DNI / CUIT / CUIL"
            value={cliente ? cliente.numeroDocumento : ""}
            fullWidth
            sx={{ ...smallFieldStyle }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
            {cliente && (cliente.emiteFacturayRemito || cliente.emiteRemito) && (
              <Chip
                label="Emite Remito"
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                  mb: 0.2,
                  height: "17px",
                }}
              />
            )}
            {condicion !== "CONTADO" && (
              <Button
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                  fontSize: 11,
                  whiteSpace: "nowrap",
                }}
                onClick={() => handleSuspenderRemito()}
                disabled={!isSuspenderRemitoEnabled}
              >
                Suspender Remito
              </Button>
            )}
          </Box>
          {condicion !== "CONTADO" && (
            <Button
              variant="contained"
              sx={{
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                padding: "0.4rem",
                fontSize: 11,
                marginTop: "0.2rem",
                whiteSpace: "nowrap",
                width: "50%",
                height: "47px",
                ml: 1,
              }}
              onClick={() => handleSuspenderCTACTE()}
            >
              Suspender CTA. CTE.
            </Button>
          )}
        </Box>

        <Typography sx={{ mt: 1, fontSize: "16px", fontWeight: "bold" }}>Ítems del comprobante</Typography>
        <Paper sx={{ width: "100%", height: window.innerWidth > 1000 ? "290px" : "190px", overflow: "hidden" }}>
          <Box sx={{ overflowY: "auto", height: "200px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontSize: "13px", fontWeight: "bold", padding: "4px" }}>
                    Cantidad
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "13px", fontWeight: "bold", padding: "4px" }}>
                    Descripción
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "13px", fontWeight: "bold", padding: "4px" }}>
                    Precio
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "13px", fontWeight: "bold", padding: "4px" }}>
                    Total
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "13px", fontWeight: "bold", padding: "4px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalProductos.map(producto => (
                  <TableRow key={producto.idProductoArray} sx={{ height: "28px" }}>
                    <TableCell align="center" sx={{ fontSize: "13px", padding: "4px" }}>
                      {producto.cantidadArticulo}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "13px", padding: "4px" }}>
                      {producto.descripcion}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "13px", padding: "4px" }}>
                      {producto.precioPublico}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "13px", padding: "4px" }}>
                      {producto.importe}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "13px", padding: "4px" }}>
                      <Button onClick={() => handleDeleteRow(producto.idProductoArray)}>
                        <HighlightOffIcon sx={{ color: "grey", fontSize: 45 }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>

        <Typography sx={{ mt: 1, fontSize: "15px", fontWeight: "bold" }}>Totales</Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", mt: "5px", width: "70%" }}>
            {renderTextField("Neto no gravado", false, "10px")}
            {renderTextField("IVA", false, "10px")}
            {renderTextField("IDC", false, "10px")}
            {renderTextField("Percepción IIBB", false, 0, clientePercepciones ? clientePercepciones : "Percepción IIBB")}
          </Box>
          <Box sx={{ display: "flex", mt: "5px" }}>
            {renderTextField("Neto gravado", false, "10px")}
            {renderTextField("ICL", false, "10px")}
            {renderTextField("Percepción IVA", false, "10px")}
            {renderTextField("Tasa vial", false, "10px")}
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "9px", marginBottom: "5px" }}>
                TOTAL
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "20px" }}>
                {total}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FacturacionFormMini;
