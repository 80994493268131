import React, { useState, useEffect } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner, actualizarDespachos } from "../helpers/pagosHelper";
import DebitoIcon from "../images/debito.png";
import EfectivoPagoIcon from "../images/efectivo-icon.png";
import CreditoIcon from "../images/credito.png";
import qr from "../images/qr.png";
import MenuSection from "../components/MenuSection";
import estadoDespacho from "../api/estadoDespacho";

const FormasDePagoPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const nic = localStorage.getItem("formattedNic");
  const bandera = localStorage.getItem("bandera");
  const listadoQR = localStorage.getItem("conListadoQR");

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);

    window.addEventListener("responsePagoConTarjeta", eventListener);
    window.addEventListener("responsePagoEfectivo", handleEfectivoEvent); // Añadido evento para efectivo

    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
      window.removeEventListener("responsePagoEfectivo", handleEfectivoEvent);
    };
  }, []);

  const handleEfectivoEvent = async event => {
    const responsePagoEfectivoJava = event.detail.success;
    localStorage.setItem("responsePagoEfectivoJava", responsePagoEfectivoJava);

    if (responsePagoEfectivoJava) {
      setIsLoading(true);

      const despachosSeleccionados = localStorage.getItem("despachosSeleccionados");

      if (despachosSeleccionados) {
        const despachosArray = JSON.parse(despachosSeleccionados); // Asegúrate de que sea un array
        try {
          // Usamos un bucle for...of para esperar cada despacho antes de navegar
          for (const despachoId of despachosArray) {
            await estadoDespacho.actualizarDespacho(despachoId, 1, 1);
          }
          navigate("/contrapage");
        } catch (error) {
          console.error("Error al actualizar despacho:", error);
          // Manejar error si es necesario
        }
      } else {
        navigate("/contrapage");
      }
    }
  };

  const handleDebito = () => {
    const valueToSend = parseFloat(localStorage.getItem("totalProductos")).toString();
    try {
      // Intenta llamar a la versión con dos parámetros (valueToSend, null)
      window.MyInterface.startPayment(valueToSend, null);
    } catch (error) {
      console.error("Falló la llamada con dos parámetros, intentando con uno.");
      // Si falla, intenta la versión con un solo parámetro (valueToSend)
      window.MyInterface.startPayment(valueToSend);
    }
  };

  const handleCloverEfectivo = () => {
    const valueToSend = parseFloat(localStorage.getItem("totalProductos")).toString();
    localStorage.removeItem("CuponClover");
    console.log(JSON.parse(localStorage.getItem("despachosSeleccionados")));

    window.MyInterface.startPaymentEfectivo(valueToSend);
  };

  const handleCredito = () => {
    navigate("/cuotaspage");
  };

  const handleQR = () => {
    if (listadoQR == "true") {
      navigate("/listadoqrpage");
    } else if (listadoQR == "false") {
      navigate("/pagoqrpage");
    } else {
      navigate("/listadoqrpage");
    }
  };

  const theme = useTheme();

  return (
    <>
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box
        sx={{
          position: "absolute",
          top: window.innerWidth < 700 ? "8%" : "12%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3
          style={{
            backgroundColor: theme.bandera.colorSecundario,
            color: "white",
            fontWeight: "bold",
            padding: "20px 22px",
            width: "100%",
            textAlign: "center",
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          SELECCIONE UNA FORMA DE PAGO:
        </h3>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: window.innerWidth < 700 ? "repeat(2, 1fr)" : "repeat(3, 1fr)",
            gap: "80px",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Button sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleDebito}>
            <img src={DebitoIcon} alt="Debito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              DÉBITO
            </Typography>
          </Button>
          <Button sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleCredito}>
            <img src={CreditoIcon} alt="Credito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              CRÉDITO
            </Typography>
          </Button>
          <Button sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleDebito}>
            <img src={qr} alt="QR FISERV" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              QR FISERV
            </Typography>
          </Button>
          <Button sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleCloverEfectivo}>
            <img src={EfectivoPagoIcon} alt="Efectivo" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              EFECTIVO
            </Typography>
          </Button>
          {(bandera === "ypf" || bandera === "shell") && (
            <Button
              sx={{
                width: "70px",
                height: "70px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={handleQR}
            >
              <img src={theme.bandera.logoPago} alt="Clover" style={{ width: "60px" }} />
              <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
                {theme.bandera.textoBotonPago}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormasDePagoPage;
