import axios from "axios";

const actualizarDespacho = async (idDespacho, elegido, facturado) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    idDespacho, // Usar el idDespacho pasado como parámetro
    elegido, // Usar el valor de elegido pasado como parámetro
    facturado, // Usar el valor de facturado pasado como parámetro
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Despachos/SeleccionarYFacturarDespacho`, userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default { actualizarDespacho };
