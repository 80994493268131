import QRCode from "qrcode";

export const inicializarCanvas = (canvasWidth, canvasHeight) => {
  const canvas = document.createElement("canvas");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const context = canvas.getContext("2d");
  context.fillStyle = "white";
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "black";

  return context;
};

export const agregarEncabezado = (context, estacionData, fecha, hora, y) => {
  context.font = "bold 35px Arial";
  context.fillText(estacionData.razonSocial || "NOMBRE DE LA ESTACION", 50, y);
  context.font = "24px Arial";
  y += 35;
  context.fillText(estacionData.domicilio || "Domicilio estacion", 5, y);
  context.fillText(estacionData.localidad || "Localidad estacion", 370, y);
  y += 35;
  context.font = "bold 26px Arial";
  context.fillText("CUIT:", 5, y);
  context.font = "24px Arial";
  context.fillText(estacionData.claveTributaria || "11-12345678-5", 80, y);
  context.fillText(fecha, 275, y);
  context.fillText(hora, 430, y);
  y += 35;
  context.font = "bold 26px Arial";
  context.fillText("Ingresos Brutos:", 5, y);
  context.font = "24px Arial";
  context.fillText(estacionData.numeroIIBB || "11-12345678-5", 235, y);
  y += 35;
  context.font = "bold 26px Arial";
  context.fillText("IVA: ", 5, y);
  context.font = "24px Arial";
  context.fillText(estacionData.categoriaIVA, 70, y);

  return y + 35;
};

export const agregarTitulo = (context, tipoDocumento, codigoDocumento, prefijo, numero, y) => {
  context.font = "bold 35px Arial";
  y += 15;
  if (tipoDocumento === "REMITO") {
    context.fillText(tipoDocumento, 100, y);
    context.fillText(`${prefijo || ""}-${numero || ""}`, 295, y);
  } else {
    context.fillText(tipoDocumento, 5, y);
    context.fillText(codigoDocumento === "COD.01" ? "A" : "B", 230, y);
    context.fillText(`${prefijo || ""}-${numero || ""}`, 295, y);
  }

  context.font = "22px Arial";

  if (tipoDocumento !== "REMITO") {
    y += 30;

    context.fillText(codigoDocumento, 210, y);
  }

  y += 5;
  context.moveTo(1, y);
  context.lineTo(10000, y);
  context.stroke();
  y += 15;

  return y;
};

export const agregarDatosCliente = (context, cliente, y) => {
  if (cliente) {
    if (cliente.razonSocial) {
      y += 35;
      context.fillText(cliente.razonSocial, 5, y);
    }
    if (cliente.numeroDocumento) {
      y += 35;
      context.fillText(cliente.numeroDocumento, 5, y);
    }
    if (cliente.categoriaIVA && cliente.categoriaIVA.descripcionCategoriaIVA) {
      y += 35;
      context.fillText(cliente.categoriaIVA.descripcionCategoriaIVA, 5, y);
    }
    y += 35;
    if (cliente.calle) {
      context.fillText(cliente.calle, 5, y);
      y += 35;
    }
    if (cliente.localidad && cliente.localidad.nombreLocalidad) {
      context.fillText(cliente.localidad.nombreLocalidad, 5, y);
    }
    y += 35;
    if (cliente.localidad && cliente.localidad.provincia && cliente.localidad.provincia.nombreProvincia) {
      context.fillText(cliente.localidad.provincia.nombreProvincia, 5, y);
    }
    if (cliente.condicionVenta && cliente.condicionVenta.descripcionCondicionVenta) {
      y += 35;
      context.fillText(cliente.condicionVenta.descripcionCondicionVenta, 5, y);
    }
  } else {
    y += 35;
    context.fillText("CONSUMIDOR FINAL", 5, y);
    y += 30;
    context.fillText("CONTADO", 5, y);
  }
  return y + 35;
};

export const agregarArticulosYDespachos = (context, facturaInfo, y) => {
  context.font = "bold 25px Arial";
  context.fillText("Descripción", 5, y);
  y += 35;
  context.fillText("Cantidad", 5, y);
  context.fillText("Precio Unit.", 220, y);
  context.fillText("Total", 440, y);
  y += 10;
  context.moveTo(1, y);
  context.lineTo(20000, y);
  context.stroke();
  y += 30;

  // Imprimir artículos
  if (facturaInfo.articulos) {
    context.font = "28px Arial";
    facturaInfo.articulos.forEach(articulo => {
      const maxWidth = 550; // Ajusta este valor según sea necesario

      // Verificar si el nombre del artículo excede el ancho máximo
      if (context.measureText(articulo.descripcion).width > maxWidth) {
        // Si excede el ancho máximo, dividir el nombre en partes de ancho permitido
        const words = articulo.descripcion.split(" ");
        let line = "";
        let lines = [];
        for (let i = 0; i < words.length; i++) {
          const testLine = line + words[i] + " ";
          const testWidth = context.measureText(testLine).width;
          if (testWidth > maxWidth) {
            lines.push(line);
            line = words[i] + " ";
          } else {
            line = testLine;
          }
        }
        lines.push(line);

        // Dibujar las líneas en el canvas
        for (let i = 0; i < lines.length; i++) {
          context.fillText(lines[i], 5, y); // Ajusta la posición x según sea necesario
          y += 40; // Ajusta la posición y según sea necesario
        }
      } else {
        // Si no excede el ancho máximo, simplemente dibujar el nombre en el canvas
        context.fillText(articulo.descripcion, 5, y); // Ajusta la posición x y según sea necesario
        y += 40; // Ajusta la posición y según sea necesario
      }

      context.fillText((articulo.cantidadArticulo !== undefined ? articulo.cantidadArticulo : articulo.cantidad).toFixed(2), 5, y);
      context.fillText((articulo.importe / articulo.cantidadArticulo).toFixed(2), 220, y);
      context.fillText(articulo.importe.toFixed(2), 415, y);
      y += 40;
    });
  } else {
    console.log("facturaInfo o facturaInfo.articulos es nulo o no está definido.");
  }

  if (facturaInfo.despachos) {
    context.font = "28px Arial";
    facturaInfo.despachos.forEach(despacho => {
      context.fillText(despacho.descripcionArticulo, 5, y); // Ajusta la posición x y según sea necesario
      y += 40; // Ajusta la posición y según sea necesario
      context.fillText(despacho.cantidad.toFixed(2), 5, y);
      context.fillText(despacho.importe.toFixed(2), 220, y);
      context.fillText(despacho.importe.toFixed(2), 415, y);
      y += 40; // Ajusta la posición y según sea necesario
    });
  } else {
    console.log("facturaInfo o facturaInfo.despachos es nulo o no está definido.");
  }

  return y;
};

export const agregarImpuestos = (context, facturaFinal, totalProductos, tipoFactura, percibePercepciones, y) => {
  context.font = "bold 28px Arial";
  context.fillText(tipoFactura === "A" ? "Neto" : "Subtotal", 5, y);
  context.fillText("IVA %", 145, y);
  context.fillText("IVA", 265, y);
  context.fillText("Perc. IIBB ER", 355, y);
  y += 30;
  context.font = "28px Arial";

  if (tipoFactura === "A") {
    context.fillText(`${facturaFinal?.detallesMovimiento.neto.toFixed(2) || ""}`, 5, y);
  } else {
    context.fillText(totalProductos.toFixed(2), 5, y);
  }

  context.fillText(`${facturaFinal?.detallesMovimiento.porcentajeIVA.toFixed(2) || ""}`, 145, y);
  context.fillText(`${facturaFinal?.detallesMovimiento.iVA.toFixed(2) || ""}`, 250, y);

  if (tipoFactura === "A") {
    context.fillText(`${facturaFinal?.detallesMovimiento.percepcionIIBB.toFixed(2) || 0}`, 440, y);
  } else {
    context.fillText(0, 450, y); // Esto está hardcodeado
  }

  y += 40;

  // Lógica para manejar despachos
  context.font = "bold 28px Arial";
  context.fillText("ICL", 5, y);
  context.fillText("IDC", 240, y);
  context.fillText("Total", 420, y);
  y += 30;
  context.font = "28px Arial";
  context.fillText(`${facturaFinal?.detallesMovimiento.iTC.toFixed(2)}`, 5, y);
  context.fillText(`${facturaFinal?.detallesMovimiento.iDC.toFixed(2)}`, 240, y);
  context.fillText(`${totalProductos.toFixed(2)}`, 400, y);
  y += 40;

  return y;
};

export const agregarEmpleadoResponsable = (context, y) => {
  context.font = "bold 28px Arial";
  context.fillText("Empleado Responsable", 5, y);
  context.font = "28px Arial";
  const nombreCompleto = localStorage.getItem("empleadoVendedor").split(" ");
  const primerNombre = nombreCompleto[0] || "";
  const segundoNombre = nombreCompleto[1] || "";
  context.fillText(primerNombre, 360, y);
  y += 30;
  context.fillText(segundoNombre, 360, y);
  y += 10;

  return y;
};

export const agregarCAEyFechaDeVencimiento = (context, facturaFinal, y) => {
  context.font = "bold 26px Arial";
  context.fillText("CAE:", 5, y);
  context.font = "26px Arial";
  context.fillText(facturaFinal?.cAENumero || "N/A", 80, y);
  context.font = "bold 26px Arial";
  y += 40;
  context.fillText("Vto. CAE:", 5, y);
  context.font = "26px Arial";

  let fechaVencimientoFormateada = "N/A";
  if (facturaFinal?.cAEFechaVencimiento) {
    const fechaVencimiento = new Date(facturaFinal?.cAEFechaVencimiento);
    const diaVencimiento = fechaVencimiento.getDate().toString().padStart(2, "0");
    const mesVencimiento = (fechaVencimiento.getMonth() + 1).toString().padStart(2, "0");
    const anioVencimiento = fechaVencimiento.getFullYear();
    fechaVencimientoFormateada = `${diaVencimiento}-${mesVencimiento}-${anioVencimiento}`;
  }

  context.fillText(fechaVencimientoFormateada, 130, y);
  y += 10;

  return y;
};

export const agregarCodigoQR = async (context, facturaFinal, formattedDate, totalProductos, cliente, estacionData, y) => {
  const qrImage = await generateQRCodeAsImage(facturaFinal, formattedDate, totalProductos, cliente, estacionData);
  if (qrImage) {
    context.drawImage(qrImage, 30, y);
    y += 40;
  }
  return y;
};

async function generateQRCodeAsImage(facturaFinal, formattedDate, totalProductos, cliente, estacionData) {
  const formattedDateRFC3339 = formattedDate.split("/").reverse().join("-");
  let numeroDocumentoSinGuiones = cliente?.numeroDocumento?.replace(/-/g, "") || null;
  let cuitEstacion = estacionData.data.claveTributaria.replace(/-/g, "");
  numeroDocumentoSinGuiones = parseInt(numeroDocumentoSinGuiones, 10);
  cuitEstacion = parseInt(cuitEstacion, 10);
  const nroCAE = parseInt(facturaFinal?.cAENumero, 10);

  const qrJSON = {
    ver: 1,
    fecha: formattedDateRFC3339,
    cuit: cuitEstacion,
    ptoVta: facturaFinal?.prefijo,
    tipoCmp: facturaFinal.tipoCmp,
    nroCmp: facturaFinal.numero,
    importe: totalProductos,
    moneda: "PES",
    ctz: 1, // 1 para pesos
    tipoDocRec: 80, // 80 para cuit
    nroDocRec: numeroDocumentoSinGuiones,
    tipoCodAut: "E",
    codAut: nroCAE,
  };

  const datosBase64 = btoa(JSON.stringify(qrJSON));
  const urlQR = `https://www.afip.gob.ar/fe/qr/?p=${datosBase64}`;

  try {
    const url = await QRCode.toDataURL(urlQR, { width: 500 });
    const image = new Image();
    image.src = url;
    return new Promise(resolve => {
      image.onload = () => {
        resolve(image);
      };
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const agregarNombreChofer = (context, y) => {
  const chofer = localStorage.getItem("chofer");
  let nombreChofer = JSON.parse(chofer)?.nombreChofer || "";
  let kilometraje = JSON.parse(chofer)?.kilometraje || "";
  let numeroVehiculo = JSON.parse(chofer)?.numeroVehiculo || "";
  let patente = JSON.parse(chofer)?.patente || "";

  if (nombreChofer && nombreChofer.trim() !== "") {
    context.font = "bold 28px Arial";
    context.fillText("Chofer:", 5, y);
    context.font = "28px Arial";
    context.fillText(nombreChofer, 170, y);
    y += 30;
  }

  if (kilometraje && kilometraje.trim() !== "") {
    context.font = "bold 28px Arial";
    context.fillText("Kilometraje:", 5, y);
    context.font = "28px Arial";
    context.fillText(kilometraje, 170, y);
    y += 30;
  }

  if (numeroVehiculo && numeroVehiculo.trim() !== "") {
    context.font = "bold 28px Arial";
    context.fillText("Vehículo:", 5, y);
    context.font = "28px Arial";
    context.fillText(numeroVehiculo, 170, y);
    y += 30;
  }

  if (patente && patente.trim() !== "") {
    context.font = "bold 28px Arial";
    context.fillText("Patente:", 5, y);
    context.font = "28px Arial";
    context.fillText(patente, 170, y);
    y += 30;
  }

  return y;
};

export const agregarDetallesMovimiento = (context, detallesMovimiento, y) => {
  const remitosFiscalesValorizado = localStorage.getItem("remitosFiscalesValorizado");
  context.font = "bold 25px Arial";
  context.fillText("Descripción", 5, y);
  y += 35;
  context.fillText("Cantidad", 5, y);
  context.fillText("Precio Unit.", 220, y);
  context.fillText("Total", 440, y);
  y += 10;
  context.moveTo(1, y);
  context.lineTo(20000, y);
  context.stroke();
  y += 30;

  // Imprimir artículos
  if (detallesMovimiento && detallesMovimiento.detallesArticulos) {
    context.font = "28px Arial";
    detallesMovimiento.detallesArticulos.forEach(articulo => {
      const maxWidth = 550; // Ajusta este valor según sea necesario

      // Verificar si el nombre del artículo excede el ancho máximo
      if (context.measureText(articulo.descripcion).width > maxWidth) {
        // Si excede el ancho máximo, dividir el nombre en partes de ancho permitido
        const words = articulo.descripcion.split(" ");
        let line = "";
        let lines = [];
        for (let i = 0; i < words.length; i++) {
          const testLine = line + words[i] + " ";
          const testWidth = context.measureText(testLine).width;
          if (testWidth > maxWidth) {
            lines.push(line);
            line = words[i] + " ";
          } else {
            line = testLine;
          }
        }
        lines.push(line);

        // Dibujar las líneas en el canvas
        for (let i = 0; i < lines.length; i++) {
          context.fillText(lines[i], 5, y); // Ajusta la posición x según sea necesario
          y += 40; // Ajusta la posición y según sea necesario
        }
      } else {
        // Si no excede el ancho máximo, simplemente dibujar el nombre en el canvas
        context.fillText(articulo.descripcion, 5, y); // Ajusta la posición x  y según sea necesario
        y += 40; // Ajusta la posición y según sea necesario
      }

      context.fillText(articulo.cantidad.toFixed(2), 5, y);

      if (remitosFiscalesValorizado === "1") {
        context.fillText(articulo.precio.toFixed(2), 220, y);
        context.fillText((articulo.precio * articulo.cantidad).toFixed(2), 415, y);
      }

      y += 40;
    });
  } else {
    console.log("detallesMovimiento o detallesMovimiento.detallesArticulos es nulo o no está definido.");
  }

  return y;
};
