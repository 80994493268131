import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import {
  inicializarCanvas,
  agregarEncabezado,
  agregarDatosCliente,
  agregarArticulosYDespachos,
  agregarImpuestos,
  agregarEmpleadoResponsable,
  agregarCAEyFechaDeVencimiento,
  agregarCodigoQR,
  agregarTitulo,
  agregarNombreChofer,
  agregarDetallesMovimiento,
} from "../helpers/facturaUtils";

function FacturaA() {
  const navigate = useNavigate();
  const [printing, setPrinting] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const processCompletedFacturaA = localStorage.getItem("processCompletedFacturaA");
    if (processCompletedFacturaA === "true") {
      navigate("/finalizarPago");
    } else {
      localStorage.setItem("processCompletedFacturaA", "true");
      generarFacturasA();
    }
  }, []);

  const generarFacturasA = async () => {
    const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
    const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
    const clienteString = localStorage.getItem("cliente");
    const cliente = clienteString ? JSON.parse(clienteString) : null;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("es-AR");
    const formattedTime = currentDate.toLocaleTimeString("es-AR");
    const canvasHeight = +localStorage.getItem("canvasHeight");
    const estacionData = JSON.parse(localStorage.getItem("estacionData"));
    const facturaFinal = JSON.parse(localStorage.getItem("facturaFinal"));
    const percibePercepciones = localStorage.getItem("percibePercepciones");
    const cantidadCopias = localStorage.getItem("cantidadCopiasFAA");

    for (let i = 0; i < facturaFinal.respuestas.length; i++) {
      const context = inicializarCanvas(550, canvasHeight + 100);

      let y = 30;

      y = agregarEncabezado(context, estacionData.data, formattedDate, formattedTime, y);

      y = agregarTitulo(context, "FACTURA", "COD.01", facturaFinal?.respuestas[i]?.prefijo, facturaFinal?.respuestas[i]?.numero, y);

      y = agregarDatosCliente(context, cliente, y);
      y += 20;
      y = agregarNombreChofer(context, y);
      y += 20;
      y = agregarDetallesMovimiento(context, facturaFinal?.respuestas[i]?.detallesMovimiento, y);
      y += 20;

      y = agregarImpuestos(context, facturaFinal.respuestas[i], facturaFinal.respuestas[i].detallesMovimiento.total, "A", percibePercepciones, y);

      y = agregarEmpleadoResponsable(context, y);

      y = agregarCAEyFechaDeVencimiento(context, facturaFinal.respuestas[i], y);

      y = await agregarCodigoQR(
        context,
        facturaFinal.respuestas[i],
        formattedDate,
        facturaFinal.respuestas[i].detallesMovimiento.total,
        cliente,
        estacionData,
        y,
      );

      const dataURL = context.canvas.toDataURL("image/png");
      const base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
      console.log(base64Data);
      for (let j = 0; j < cantidadCopias; j++) {
        if (!(window.location.hostname === "localhost" && window.location.port === "3000")) {
          window.MyInterface.imprimirFacturaAfip(base64Data);
        }
      }
    }

    setPrinting(true);

    setTimeout(() => {
      navigate("/finalizarPago");
    }, 2000);
  };

  return (
    <div>
      <MenuSection />
      <Box className="printing-container" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress sx={{ color: theme.bandera.colorSecundario }} size={50} />
        <Typography variant="body1" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Imprimiendo comprobante...
        </Typography>
      </Box>
    </div>
  );
}

export default FacturaA;
