import axios from "axios";

const postCierreDeTurno = async (cierreDeTurno) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    cierreDeTurno: cierreDeTurno,
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/CierreDeTurno/CerrarTurno`, userData);
    return response.data;
   } catch (error) {
    console.log(error.response.data.message);
    localStorage.setItem("errorMensajeCierreTurno", error.response.data.message);
    // localStorage.setItem("reintentos", error.response.data.reintentos);
  }
};

const cierreDeTurno = {
  postCierreDeTurno,
};

export default cierreDeTurno;
