import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const HomeButton = ({ icon, label, onClick }) => {
  const theme = useTheme();
  const buttonStyles = {
    margin: "0 auto",
    height: window.innerWidth < 700 ? 90 : 98,
    width: "100%",
    maxWidth: "360px",
    backgroundColor: theme.bandera.colorSecundario,
    borderRadius: 0,
    marginBottom: 1,
    color: "white",
    ":hover": {
      backgroundColor: theme.bandera.colorSecundario,
    },
  };

  return (
    <Button sx={buttonStyles} onClick={onClick}>
      {icon}
      <p
        style={{
          fontSize: 17,
          color: "white",
          lineHeight: "20px",
          textAlign: "left",
          fontWeight: 600,
        }}
      >
        {label}
      </p>
    </Button>
  );
};

export default HomeButton;
