import axios from "axios";

const postVenta = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const facturaJson = localStorage.getItem("facturaJson");
  const cuponClover = localStorage.getItem("CuponClover");
  const userData = { nic: formattedNic, token: token, venta: facturaJson, cuponClover };
  try {
    const response = await axios.post(
      `https://facturador-proxy.azurewebsites.net/api/venta`,
      //  `https://d6jtrh-8080.csb.app/api/venta`,
      userData,
      {
        timeout: 300000,
      },
    );
    console.log(response.data);
    localStorage.setItem("CAE", response.data.CAE);
    localStorage.setItem("prefijo", response.data.Prefijo);
    localStorage.setItem("numero", response.data.Numero);
    return response;
  } catch (error) {
    console.log(error.response.data.message);
    localStorage.setItem("errorMensaje", error.response.data.exceptionMessage);
    localStorage.setItem("reintentos", error.response.data.reintentos);
  }
};

const venta = {
  postVenta,
};

export default venta;
