// store/index.js
import { createStore, combineReducers } from 'redux';
import productosReducer from '../reducers/productosReducer';
import { composeWithDevTools } from "redux-devtools-extension"; // Asegúrate de importar composeWithDevTools
import articulosReducer from '../reducers/articulosReducer';

const rootReducer = combineReducers({
  productos: productosReducer,
  articulos: articulosReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
