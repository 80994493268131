import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import logoShell from "./images/logoShell.png";
import logoYpf from "./images/logoYpf.png";
import logoYpfPago from "./images/ypf-icon.png";
import logoShellPago from "./images/shell-box-icon.png";
import logoAxion from "./images/axion.png";
import logoPuma from "./images/logo-puma-cuadrado.png";

// Configuración para cada marca
const configuraciones = {
  ypf: {
    colorPrimario: "#005090",
    colorSecundario: "#005090",
    image: logoYpf,
    colorLogin: "#fff",
    logoPago: logoYpfPago,
    textoBotonPago: "YPF APP",
  },
  shell: {
    colorPrimario: "#FBCE07",
    colorSecundario: "#dd1d21",
    image: logoShell,
    colorLogin: "#000",
    logoPago: logoShellPago,
    textoBotonPago: "SHELL BOX",
  },
  axion: {
    colorPrimario: "#101760",
    colorSecundario: "#CA117C",
    image: logoAxion,
    colorLogin: "#000",
    textoBotonPago: "",
  },
  puma: {
    colorPrimario: "#DB1F2A",
    colorSecundario: "#00784A",
    image: logoPuma,
    colorLogin: "#000",
    textoBotonPago: "",
  },
  // Agrega más configuraciones aquí según sea necesario
};

// Función para obtener la configuración basada en la marca
const getTheme = marca => {
  const bandera = configuraciones[marca] || configuraciones.axion; // valor por defecto si no se encuentra la marca
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 650,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    bandera: bandera,
  });
};

const RootComponent = () => {
  const [theme, setTheme] = useState(getTheme(localStorage.getItem("bandera")));

  useEffect(() => {
    const updateTheme = () => {
      const storedMarca = localStorage.getItem("bandera");
      const newTheme = getTheme(storedMarca);
      setTheme(newTheme);
    };

    updateTheme();

    // Agregar listener para cambios en la marca (bandera)
    window.addEventListener("storage", updateTheme);

    // Limpiar listener al desmontar el componente
    return () => {
      window.removeEventListener("storage", updateTheme);
    };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
reportWebVitals();
