import axios from "axios";

const getBanderas = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Bandera/ObtenerBandera`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const banderas = {
  getBanderas,
};

export default banderas;
