import React, { useState, useEffect, useCallback } from "react";
import { Modal, Box, TextField, ListItemText, List, IconButton, ListItem, CircularProgress, Paper, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Articulos from "../api/articulosListado";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash.debounce";

const ArticulosSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [articulosArray, setArticulosArray] = useState([]);
  const [activeArticulos, setActiveArticulos] = useState({});
  const [contadorGlobal, setContadorGlobal] = useState({});
  const dispatch = useDispatch();
  const [showButtons, setShowButtons] = useState(false);
  const [showAllResults] = useState(false); // Estado para mostrar todos los resultados
  const totalArticulos = useSelector(state => state.articulos.articulosClickeados);
  const idProductoArray = useSelector(state => state.productos.idProductoArray);
  const theme = useTheme();
  const navigate = useNavigate(); // Define useNavigate para la navegación

  function filtrarArticulos(articulos) {
    const perteneceFULL = JSON.parse(localStorage.getItem("perteneceFULL"));
    return articulos.filter(articulo => (perteneceFULL ? articulo.codigoYPF !== null : articulo.codigoYPF === null));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let articulosFiltrados = [];
        const response = await Articulos.listado("");
        let articulos = response.data.articulos;
        articulos = filtrarArticulos(articulos);
        console.log(articulos);
        // Ordenar antes de aplicar el filtro
        articulosFiltrados = Object.values(articulos).sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        setSearchResults(articulosFiltrados);
        setArticulosArray(articulosFiltrados);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      if (window.innerWidth < 700) {
        setModalWidth("360px");
      } else {
        setModalWidth("900px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const eventListener = async event => {
      const barcode = event.detail.barcode;
      setSearchTerm(barcode);

      // Llama a handleIncrement con el artículo correspondiente
      const articuloEncontrado = articulosArray.find(articulo => articulo.codigoBarra.toString() === barcode);

      if (articuloEncontrado) {
        handleIncrementScanner(articuloEncontrado);
      }
    };

    window.addEventListener("scanComplete", eventListener);

    // Asegúrate de retirar el escuchador cuando el componente se desmonte
    return () => {
      window.removeEventListener("scanComplete", eventListener);
    };
  }, [articulosArray]); // Asegúrate de agregar articulosArray como dependencia si se utiliza dentro del useEffect

  const handleAbrirScanner = () => {
    window.MyInterface.scanProduct();
  };

  const handleSearch = async () => {
    const searchTermLowerCase = searchTerm.toLowerCase().trim(); // Elimina espacios en blanco al principio y al final
    setIsLoading(true);
    try {
      let results = [];
      const response = await Articulos.listado(searchTermLowerCase);
      console.log("HICE UN FETCH ---------------------------------------");
      let articulos = response.data.articulos;
      articulos = filtrarArticulos(articulos);
      setArticulosArray(articulos);
      console.log(articulos);
      // Ordenar antes de aplicar el filtro
      results = Object.values(articulos).sort((a, b) => a.descripcion.localeCompare(b.descripcion));
      setSearchResults(results.slice(0, showAllResults ? results.length : 50));
      setShowButtons(true);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      debouncedHandleSearch();
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm, debouncedHandleSearch]);

  const handleAgregar = () => {
    const totalArticulosConImporte = totalArticulos.map(articulo => ({
      ...articulo,
      importe: articulo.cantidadArticulo * articulo.precioPublico,
    }));

    let idProductoArrayActual = idProductoArray;

    totalArticulosConImporte.forEach(articulo => {
      totalArticulos.forEach(articuloAux => {
        if (!articuloAux.idProductoArray) {
          articulo.idProductoArray = idProductoArrayActual;
          idProductoArrayActual += 1;
          console.log(totalArticulos);
        }
      });
      dispatch({ type: "AGREGAR_PRODUCTO", payload: articulo });
      //ACA TENGO Q RESOLVERLO
    });

    dispatch({
      type: "ACTUALIZAR_ID_PRODUCTO_ARRAY",
      payload: idProductoArrayActual,
    });

    // Navegación condicional
    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya === "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch({ type: "REINICIAR_ARTICULOS" });
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleCancelar = () => {
    setSearchTerm("");
    setSearchResults([]);
    window.history.back();
  };

  const handleIncrement = articulo => {
    let articuloEncontrado = null;

    articulosArray.forEach(articuloArray => {
      if (articuloArray.idArticulo === articulo.idArticulo) {
        articuloEncontrado = articulo;
        dispatch({ type: "INCREMENTAR_ARTICULO", payload: articulo });
      }
    });
    setActiveArticulos(prevActiveArticulos => ({
      ...prevActiveArticulos,
      [articulo.idArticulo]: true,
    }));
    setContadorGlobal(prevContadorGlobal => ({
      ...prevContadorGlobal,
      [articulo.idArticulo]: (prevContadorGlobal[articulo.idArticulo] || 0) + 1,
    }));
  };

  const handleIncrementScanner = articulo => {
    const articuloEncontrado = articulosArray.find(articuloArray => articuloArray.idArticulo === articulo.idArticulo);

    if (articuloEncontrado) {
      setActiveArticulos(prevActiveArticulos => ({
        ...prevActiveArticulos,
        [articulo.idArticulo]: true,
      }));

      setContadorGlobal(prevContadorGlobal => ({
        ...prevContadorGlobal,
        [articulo.idArticulo]: (prevContadorGlobal[articulo.idArticulo] || 0) + 1,
      }));

      dispatch({ type: "INCREMENTAR_ARTICULO", payload: articulo });
    }
  };

  const handleDecrement = articulo => {
    if (contadorGlobal[articulo.idArticulo] > 0) {
      // Verifica que el contador sea mayor que cero antes de decrementar
      setContadorGlobal(prevContadorGlobal => ({
        ...prevContadorGlobal,
        [articulo.idArticulo]: prevContadorGlobal[articulo.idArticulo] - 1,
      }));
    }

    if (contadorGlobal[articulo.idArticulo] <= 1) {
      // Oculta el TextField y el contador si el contador global es menor o igual a 0
      setActiveArticulos(prevActiveArticulos => ({
        ...prevActiveArticulos,
        [articulo.idArticulo]: false,
      }));
    }
    dispatch({ type: "DECREMENTAR_ARTICULO", payload: articulo });
  };

  return (
    <>
      <MenuSection />
      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "48%" : "56.5%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: "8px 25px 0px 25px",
          height: window.innerWidth < 700 ? "80%" : "86%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: window.innerWidth > 700 ? "row" : "column",
            alignItems: window.innerWidth < 700 ? "left" : "center",
            marginBottom: 2,
            whiteSpace: "nowrap",
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 13,
              mb: window.innerWidth > 700 ? 0 : 1,
              marginRight: window.innerWidth > 700 ? 2 : 0,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleAbrirScanner}
          >
            LEER CODIGO DE BARRAS
          </Button>

          <TextField
            label="Nombre o código del artículo"
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
              debouncedHandleSearch();
            }}
            variant="outlined"
            fullWidth
            sx={{
              flexGrow: 1,
              mb: window.innerWidth > 700 ? 0 : 2,
              width: window.innerWidth > 700 ? "70%" : "100%",
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
                </IconButton>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
        </Box>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 367 : 291,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </div>
        ) : (
          <>
            <Paper
              sx={{
                height: window.innerWidth < 700 ? 350 : 275,
                overflow: "auto",
                boxShadow: 0,
                marginBottom: 2,
              }}
            >
              {searchResults.map(
                (
                  articulo, // Cambia 'articulosArray' por 'searchResults' aquí
                ) => (
                  <List key={articulo.idArticulo}>
                    <ListItem
                      key={1}
                      sx={{
                        borderBottom: "1px solid #ccc",
                        display: "flex",
                      }}
                    >
                      <ListItemText
                        primary={`${articulo.descripcion}`}
                        secondary={`$${articulo.precioPublico.toFixed(2)}`}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          "& .MuiListItemText-primary": {
                            marginRight: 1.5, // Ajusta el espacio entre primary y secondary
                          },
                        }}
                      />
                      <div
                        style={{
                          display: "grid",
                          textAlign: "center",
                          marginLeft: 20,
                        }}
                      >
                        {activeArticulos[articulo.idArticulo] && (
                          <TextField
                            value={contadorGlobal[articulo.idArticulo] || 0}
                            size="small"
                            disabled
                            style={{
                              width: 70,
                              border: "none", // Quita el borde
                              padding: 0,
                              textAlign: "center", // Centra horizontalmente el texto
                            }}
                          />
                        )}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "10px",
                          }}
                        >
                          <AddCircleIcon sx={{ color: theme.bandera.colorSecundario, fontSize: "2.5em" }} onClick={() => handleIncrement(articulo)} />
                          <RemoveCircleIcon sx={{ color: theme.bandera.colorSecundario, fontSize: "2.5rem" }} onClick={() => handleDecrement(articulo)} />
                        </div>
                      </div>
                    </ListItem>
                  </List>
                ),
              )}
            </Paper>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  marginRight: window.innerWidth < 700 ? 5 : 0,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleAgregar}
              >
                AGREGAR
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "1rem",
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleCancelar}
              >
                CANCELAR
              </Button>
            </Box>
          </>
        </div>
      </Box>
    </>
  );
};

export default ArticulosSearchPage;
