import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, ListItemText, List, IconButton, ListItem, CircularProgress, Paper, Button } from "@mui/material";
import debounce from "lodash.debounce";
import SearchIcon from "@mui/icons-material/Search";
import Choferes from "../api/choferes";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";

const FormChoferConCliente = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [noChoferes, setNoChoferes] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const choferes = await Choferes.getChoferes("");
        const parsedClientes = Object.values(choferes.data.choferes);
        setNoChoferes(false);
        setSearchResults(parsedClientes);
      } catch (error) {
        setNoChoferes(true);
        console.error("Error al obtener los datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      setModalWidth(window.innerWidth < 700 ? "360px" : "900px");
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = useCallback(async () => {
    if (searchTerm.trim() === "") return;

    setIsLoading(true);
    try {
      const response = await Choferes.getChoferes(searchTerm);
      const choferesData = response.data.choferes; // Accede directamente a `choferes`
      console.log(choferesData);

      setSearchResults(choferesData); // Muestra los primeros 50 resultados
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const debouncedSearch = useCallback(
    debounce(() => {
      handleSearch();
    }, 1000),
    [handleSearch], // Nota: usa `handleSearch` como dependencia
  );

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  const handleRowClick = chofer => {
    const choferStr = JSON.stringify(chofer);
    localStorage.setItem("chofer", choferStr);
    navigate("/datoschofer");
  };

  const handleCancelar = () => {
    setSearchTerm("");
    setSearchResults([]);
    localStorage.removeItem("chofer");
    navigate("/dashboard");
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "50%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "90%",
        }}
      >
        <TextField
          label="Nombre del chofer"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={event => {
            if (event.key === "Enter") {
              handleSearch();
            }
          }}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "0.85rem", color: "gray" },
          }}
        />

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 375 : 290,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </Box>
        ) : noChoferes ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.innerWidth < 700 ? 375 : 290,
            }}
          >
            No hay choferes asignados.
          </Box>
        ) : (
          <Paper
            sx={{
              height: window.innerWidth < 700 ? 375 : 275,
              overflow: "auto",
              boxShadow: 0,
              marginBottom: 2,
            }}
          >
            {searchResults.map(chofer => (
              <List key={chofer.idChofer}>
                <ListItem key={chofer.idChofer} sx={{ borderBottom: "1px solid #ccc", display: "flex" }} onClick={() => handleRowClick(chofer)}>
                  <ListItemText
                    primary={chofer.nombreChofer}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  />
                </ListItem>
              </List>
            ))}
          </Paper>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginLeft: "1rem",
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleCancelar}
          >
            CANCELAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FormChoferConCliente;
