export const validateCUITCUIL = (cuit) => {
  if (cuit.length !== 11) return false;

  let rv = false;
  let resultado = 0;
  const codes = "6789456789";
  const verificador = parseInt(cuit[cuit.length - 1], 10);

  for (let x = 0; x < 10; x++) {
      const digitoValidador = parseInt(codes[x], 10);
      const digito = parseInt(cuit[x], 10);
      resultado += digitoValidador * digito;
  }

  resultado = resultado % 11;
  rv = resultado === verificador;
  return rv;
};

export const formatCuit = (value) => {
  const newValue = value.replace(/\D/g, "");
  const part1 = newValue.slice(0, 2);
  const part2 = newValue.slice(2, 10);
  const part3 = newValue.slice(10, 11);
  if (newValue.length > 10) {
      return `${part1}-${part2}-${part3}`;
  } else if (newValue.length > 2) {
      return `${part1}-${part2}`;
  } else {
      return part1;
  }
};

export const handleCuitChange = (event, setCuit, setCuitError) => {
  const formattedCuit = formatCuit(event.target.value);
  setCuit(formattedCuit);
  if (formattedCuit.replace(/\D/g, "").length === 11) {
      setCuitError(false);
  }
};
