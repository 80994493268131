import axios from "axios";

const getPercibePercepciones = async (clientePercepciones) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    clientePercepciones: clientePercepciones, // Add clienteStr to userData
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/CalcularPercepciones`, userData);
    return response.data; // Ensure to return the actual data
  } catch (error) {
    throw error;
  }
};

const percibePercepciones = {
    getPercibePercepciones,
};

export default percibePercepciones;
