import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Button, Dialog, Alert } from "@mui/material";
import MenuSection from "../components/MenuSection";
import venta from "../api/generarVentaQR";
import { useNavigate } from "react-router-dom";
import cancelarVentaQR from "../api/cancelarVentaQR";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import generarFacturaJson from "../helpers/generarFacturaJson";
import { useTheme } from "@mui/material/styles";
import { setTarjetaID } from "../helpers/pagosHelper"; // Importar el helper

export const PagoQRPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const qrImage = localStorage.getItem("urlPosicionQR");
  const posicionQR = Number(localStorage.getItem("idPosicionQR"));
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const IdEmpleadoVendedor = null; // HARDCODEADO
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  var prefijo = localStorage.getItem("prefijoFront") || null;
  const navigate = useNavigate();
  const idTalonarioQR = +localStorage.getItem("idTalonarioQR");
  const conListadoQR = JSON.parse(localStorage.getItem("conListadoQR"));
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));

  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    handleCancel();
    navigate("/pagoqrpage");
  };

  useEffect(() => {
    const obtenerIdTarjeta = async () => {
      const tarjetasData = JSON.parse(localStorage.getItem("tarjetas")); // Obtenemos el array de tarjetas
      localStorage.setItem("vengoDeQR", true);
      const idTarjeta = await setTarjetaID(localStorage.getItem("CuponClover"), tarjetasData);
      generarFactura(idTarjeta);
    };

    const generarFactura = IdTarjeta => {
      const facturaJson = generarFacturaJson(
        null, // Aquí podrías pasar CuponClover si es necesario
        puesto,
        cliente,
        facturaInfo,
        totalProductos,
        prefijo,
        emiteFacturayRemito,
      );

      // Agregar campos adicionales que no estaban en el helper
      facturaJson.cabecera.IdTarjeta = IdTarjeta;
      facturaJson.cabecera.IdTerminalTarjeta = idTalonarioQR;
      facturaJson.cabecera.IdPosicionQR = posicionQR;

      localStorage.setItem("facturaJson", JSON.stringify(facturaJson));
    };

    obtenerIdTarjeta();
  }, []);

  const generarVenta = async () => {
    try {
      const response = await venta.ventaQR();
      console.log(response);

      if (response && response.data) {
        localStorage.setItem("pagoConQR", true);
        let idsMovimientosFac = response.data;
        localStorage.setItem("idsMovimientosFac", JSON.stringify(idsMovimientosFac));

        setDialogMessage("El pago se procesó con éxito");
        setDialogSeverity("success");
        setDialogOpen(true);
        setLoading(false);
      } else if (response && response.error) {
        console.log(response.error);
        setDialogMessage(response.error); // Mostrar el mensaje de error en el diálogo
        setDialogSeverity("error");
        setDialogOpen(true);
        setLoading(false);
      } else {
        setDialogMessage("Error desconocido.");
        setDialogSeverity("error");
        setDialogOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error al generar la venta:", error);
      setDialogMessage("Error al generar la venta.");
      setDialogSeverity("error");
      setDialogOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      generarVenta();
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    if (dialogOpen && dialogSeverity === "success") {
      const timer = setTimeout(() => {
        setDialogOpen(false);
        navigate("/contrapage");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (dialogOpen && dialogSeverity === "error") {
      const timer = setTimeout(() => {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        if (esPlaya === "true") {
          setDialogOpen(false);
          navigate("/dashboard");
        } else {
          setDialogOpen(false);
          navigate("/dashboardmini");
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dialogOpen, dialogSeverity, navigate]);

  const handleCancel = async () => {
    try {
      const response = await cancelarVentaQR.cancelar();
      setDialogMessage(response.data.msj);
      setDialogSeverity("error");
      setDialogOpen(true);
      setLoading(false);
    } catch (error) {
      console.log("Error al cancelar la venta:", error);
    }
  };

  const handleRetry = () => {
    setLoading(true);
  };

  return (
    <>
      <MenuSection />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        {loading ? (
          <>
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", marginTop: 2 }}>
              Generando QR
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}>
              Escanea el QR
            </Typography>

            {!conListadoQR && (
              <Box
                component="img"
                src={qrImage}
                alt="QR Code"
                sx={{
                  width: 300,
                  height: 300,
                }}
              />
            )}

            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 4, marginTop: 2, color: "#000" }}>
              Monto: ${totalProductos.toFixed(2)}
            </Typography>

            <Button
              variant="contained"
              sx={{
                fontWeight: "bold",
                padding: "0.6rem",
                fontSize: 16,
                whiteSpace: "nowrap",
                backgroundColor: theme.bandera.colorSecundario,
              }}
              onClick={handleCancel}
            >
              CANCELAR
            </Button>
          </>
        )}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          navigate("/contrapage");
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: dialogSeverity === "success" ? "#4caf50" : "#f44336",
            padding: "60px",
            color: "white",
          }}
        >
          <Alert
            severity={dialogSeverity}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" style={{ color: "white", fontSize: "80px" }} />,
              error: <HighlightOffIcon fontSize="inherit" style={{ fontSize: "80px" }} />,
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
          >
            {dialogMessage}
          </Alert>
          <CircularProgress
            sx={{
              marginTop: "20px",
              color: "white",
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default PagoQRPage;
