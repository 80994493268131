import React from "react";
import MenuSection from "../components/MenuSection";
import Sidebar from "../components/Sidebar";
import FacturacionFormMini from "../components/FacturacionFormMini";
import FooterMini from "../components/FooterMini";

const DashboardMini = () => {
  return (
    <>
      <FooterMini />
      <Sidebar />
      <FacturacionFormMini /> 
    </>
  );
};

export default DashboardMini;
