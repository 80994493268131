import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import {
  inicializarCanvas,
  agregarEncabezado,
  agregarDatosCliente,
  agregarArticulosYDespachos,
  agregarTitulo,
  agregarNombreChofer,
  agregarDetallesMovimiento,
} from "../helpers/remitoUtils";

function Remito() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const processCompletedRemito = localStorage.getItem("processCompletedRemito");
    if (processCompletedRemito === "true") {
      navigate("/finalizarPago");
    } else {
      localStorage.setItem("processCompletedRemito", "true");
      generarRemito();
    }
  }, []);

  const generarRemito = async () => {
    const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
    const clienteString = localStorage.getItem("cliente");
    const cliente = clienteString ? JSON.parse(clienteString) : null;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("es-ES");
    const formattedTime = currentDate.toLocaleTimeString("es-ES");
    const canvasHeight = localStorage.getItem("canvasHeight");
    const estacionData = JSON.parse(localStorage.getItem("estacionData"));
    const facturaFinal = JSON.parse(localStorage.getItem("facturaFinal"));
    const cantidadCopias = localStorage.getItem("cantidadCopiasREM");
    const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");

    if (facturaInfo) {
      const context = inicializarCanvas(550, canvasHeight);

      let y = 30;

      // Agregar el encabezado
      y = agregarEncabezado(context, estacionData.data, formattedDate, formattedTime, y);

      y = agregarTitulo(context, "REMITO", "", facturaFinal?.respuestas[0]?.prefijo, facturaFinal?.respuestas[0]?.numero, y);

      // Agregar datos del cliente
      y = agregarDatosCliente(context, cliente, y);
      y += 20;
      y = agregarNombreChofer(context, y);
      y += 20;
      // Agregar artículos y despachos
      y = agregarDetallesMovimiento(context, facturaFinal?.respuestas[0]?.detallesMovimiento, y);

      const image = new Image();
      image.src = localStorage.getItem("firma");
      let base64Data = null;

      image.onload = function () {
        // Dibuja la imagen en el canvas
        const xOffset = 160;
        context.drawImage(image, xOffset, y);

        // Convertir el contenido del canvas en una imagen PNG
        const dataURL = context.canvas.toDataURL("image/png");
        base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
        console.log(base64Data);
        // Imprimir el remito la cantidad de veces especificada
        for (let i = 0; i < cantidadCopias; i++) {
          if (!(window.location.hostname === "localhost" && window.location.port === "3000")) {
            window.MyInterface.imprimirFacturaAfip(base64Data);
          }
        }

        setPrinting(true);
        setTimeout(() => {
          if (emiteFacturayRemito === "true") {
            localStorage.setItem("emiteFacturayRemito", "false");
            const imprimirFAA = true;
            const imprimir = true;
            const sinFirma = true;
console.log("ENTRO ACA???")
            navigate("/ticket/", { state: { facturaInfo, imprimir, imprimirFAA, sinFirma } });
          } else {
            navigate("/finalizarPago");
          }
        }, 2000);
      };
    }
  };

  return (
    <div>
      <MenuSection />
      <Box className="printing-container" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress sx={{ color: theme.bandera.colorSecundario }} size={50} />
        <Typography variant="body1" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Imprimiendo comprobante...
        </Typography>
      </Box>
    </div>
  );
}

export default Remito;
