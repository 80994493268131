import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner } from "../helpers/pagosHelper";
import MenuSection from "../components/MenuSection";

const CuotasPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCuota, setSelectedCuota] = useState("1"); // Estado para la cuota seleccionada

  const totalProductos = parseFloat(localStorage.getItem("totalProductos")).toFixed(2);

  // Harcodear los porcentajes
  const cuotas = [
    { cuota: "1", porcentaje: 0 }, // Sin interés
    // { cuota: "3", porcentaje: 10 }, // 10% de interés
    // { cuota: "6", porcentaje: 20 }, // 20% de interés
    // { cuota: "9", porcentaje: 30 }  // 30% de interés
  ];

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);

    window.addEventListener("responsePagoConTarjeta", eventListener);
    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
    };
  }, []);

  const calcularTotalConInteres = (total, porcentaje) => {
    return (total * (1 + porcentaje / 100)).toFixed(2);
  };

  const handleSubmit = () => {
    // Obtener el porcentaje de interés según la cuota seleccionada
    const selectedOption = cuotas.find(option => option.cuota === selectedCuota);
    const totalConInteres = calcularTotalConInteres(totalProductos, selectedOption.porcentaje);

    // Llamar al método en Java pasando el valor total con interés y el número de cuotas seleccionadas
    window.MyInterface.startPayment(totalConInteres);
  };

  const theme = useTheme();

  return (
    <>
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box mx={2} mt={window.innerWidth < 700 ? 3 : -8}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Elige la cantidad de cuotas
        </Typography>
        <Box mt={4} display="flex" flexDirection="column" sx={{ accentColor: theme.bandera.colorSecundario }} alignItems="flex-start">
          {cuotas.map(option => (
            <label key={option.cuota} style={{ marginBottom: "20px" }}>
              <input type="radio" value={option.cuota} checked={selectedCuota === option.cuota} onChange={() => setSelectedCuota(option.cuota)} />
              {option.cuota} cuota(s) de ${calcularTotalConInteres(totalProductos, option.porcentaje)} (Total: $
              {calcularTotalConInteres(totalProductos, option.porcentaje)})
            </label>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: window.innerWidth > 700 ? 2 : 32,
            display: "flex",
            justifyContent: window.innerWidth > 700 ? "center" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.bandera.colorSecundario, ":hover": { backgroundColor: theme.bandera.colorSecundario } }}
            onClick={handleSubmit}
          >
            <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              CONTINUAR
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CuotasPage;
