import axios from "axios";

const actualizarVenta = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const idEmpleadoResponsable = localStorage.getItem("idEmpleadoResponsable");
  const idsMovimientosFac = JSON.parse(localStorage.getItem("idsMovimientosFac"));

  const userData = {
    nic: formattedNic,
    token: token,
    idsMovimientosFac: idsMovimientosFac,
    idEmpleadoResponsable: idEmpleadoResponsable,
  };
  console.log(JSON.stringify(userData));
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/ActualizarVentaQR`, {
      userData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const actualizarVentaQR = {
  actualizarVenta,
};

export default actualizarVentaQR;
