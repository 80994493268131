import axios from "axios";

const listado = async searchString => {
  const formattedNic = localStorage.getItem("formattedNic");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, idCaja: puesto.caja.idCaja, pageNumber: 1, searchString: searchString };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Articulos/GetArticulosListado`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

const articulos = {
  listado,
};

export default articulos;
