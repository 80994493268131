import axios from "axios";

const getDespachos = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const cantidadSegundosDespacho = localStorage.getItem("cantidadSegundosDespacho");

  const userData = {
    nic: formattedNic,
    token: token,
    cantidadSegundosDespacho: cantidadSegundosDespacho,
  };
  try {
    const response = await axios.post(
      `https://facturador-proxy.azurewebsites.net/api/Despachos/GetDespachosInformacion`,
      userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const despachos = {
  getDespachos,
};

export default despachos;
