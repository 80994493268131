import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LogoCaldenOilBlanco from "../images/logo-caldenoil-blanco.png";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        position: "fixed",
        bottom: "0px",
        left: 0,
        width: "100%",
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "#b3b3b3",
          color: "#fff",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          Powered by
          <Box sx={{ display: "inline-block", ml: 1 }}>
            <img src={LogoCaldenOilBlanco} alt="Logo Calden Oil Blanco" style={{ height: 20, verticalAlign: "middle", marginTop: -3 }} />
          </Box>
        </Typography>

        <Typography
          component="div"
          sx={{
            fontSize: "12px",
            fontWeight: "600",  
          }}
        >
          Versión: 1.4.10
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
