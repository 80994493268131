import axios from "axios";

const getClienteAfip = async cuit => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, cuit: cuit };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Clientes/GetInformacionCUIT`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const afip = {
  getClienteAfip,
};

export default afip;
