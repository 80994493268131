import axios from "axios";

const getEmpleado = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
  };
  try {
     const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Vendedores/GetEmpleadosVendedores`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

const empleado = {
  getEmpleado,
};

export default empleado;
