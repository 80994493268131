// helpers/generarFacturaInfo.js

import { calcularTotal, combinarArticulos } from "./useArticulos";

export const generarFacturaInfo = (productos, cliente) => {
  const despachos = [];
  const articulos = [];

  productos.forEach(producto => {
    if (producto.idDespacho) {
      despachos.push(producto);
    } else {
      articulos.push(producto);
    }
  });

  const articulosCombinados = combinarArticulos(articulos);
  const total = calcularTotal(productos);

  return {
    despachos: despachos.length > 0 ? despachos : null,
    articulos: articulosCombinados.length > 0 ? articulosCombinados : null,
    cliente: cliente || null,
    total: total,
  };
};
