import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode";
import estacion from "../api/estacion";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function FacturaBExento() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [printing, setPrinting] = useState(false);
  const canvasRef = useRef(null);
  useEffect(() => {
    generarFacturaBExento();
  }, []);

  const generarFacturaBExento = async () => {
    const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
    const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
    const user = localStorage.getItem("user");
    const clienteString = localStorage.getItem("cliente");
    const cliente = clienteString ? JSON.parse(clienteString) : null;
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const canvasHeight = localStorage.getItem("canvasHeight");
    const estacionData = JSON.parse(localStorage.getItem("estacionData"));
    const facturaFinal = JSON.parse(localStorage.getItem("facturaFinal"));

    console.log("esta es la factura B Exento");

    if (facturaInfo) {
      const canvas = document.createElement("canvas"); // Crear un nuevo canvas en memoria
      canvas.width = 550;
      canvas.height = canvasHeight;
      const context = canvas.getContext("2d");
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "black";

      let y = 30;

      context.font = "bold 35px Arial";
      context.fillText(estacionData.data.razonSocial || "NOMBRE DE LA ESTACION", 50, y);
      context.font = "24px Arial";
      y += 35;
      context.fillText(estacionData.data.domicilio || "Domicilio estacion", 5, y);
      context.fillText(estacionData.data.localidad || "Localidad estacion", 370, y);
      y += 35;
      context.font = "bold 26px Arial";
      context.fillText("CUIT:", 5, y);
      context.font = "24px Arial";
      context.fillText(estacionData.data.claveTributaria || "11-12345678-5", 80, y);
      context.fillText(formattedDate, 285, y);
      context.fillText(formattedTime, 430, y);
      y += 35;
      context.font = "bold 26px Arial";
      context.fillText("Ingresos Brutos:", 5, y);
      context.font = "24px Arial";
      context.fillText(estacionData.data.numeroIIBB || "11-12345678-5", 235, y);
      y += 35;
      context.font = "bold 26px Arial";
      context.fillText("IVA: ", 5, y);
      context.font = "24px Arial";
      context.fillText(estacionData.data.categoriaIVA, 70, y);

      y += 35;
      context.font = "bold 26px Arial";
      context.fillText("Inicio Actividades:", 5, y);
      context.font = "24px Arial";
      if (estacionData.data.fechaInicioActividad) {
        const fechaInicioActividad = new Date(estacionData.data.fechaInicioActividad);
        const dia = fechaInicioActividad.getDate().toString().padStart(2, "0");
        const mes = (fechaInicioActividad.getMonth() + 1).toString().padStart(2, "0");
        const anio = fechaInicioActividad.getFullYear();
        const fechaFormateada = `${dia}/${mes}/${anio}`;
        context.fillText(fechaFormateada, 260, y);
      } else {
        context.fillText("00/00/0000", 260, y);
      }
      y += 70;

      context.font = "bold 35px Arial";
      context.fillText("FACTURA", 5, y);
      context.fillText("B", 230, y);
      context.fillText(facturaFinal.data.Prefijo + "-" + facturaFinal.data.Numero, 295, y);
      context.font = "22px Arial";
      y += 30;
      context.fillText("COD.06", 210, y);
      y += 5;
      context.moveTo(1, y);
      context.lineTo(10000, y);
      context.stroke();
      y += 30;

      context.font = "24px Arial";

      //datos del cliente
      if (cliente) {
        y += 35;
        context.fillText(cliente.razonSocial || "Razon Social Cliente", 5, y);
        y += 35;
        context.fillText(cliente.numeroDocumento || "00-00000000-0", 5, y);
        y += 35;
        context.fillText(cliente.categoriaIVA.descripcionCategoriaIVA || "Condicion IVA Cliente", 5, y);
        y += 35;
        context.fillText(cliente.calle || "Domicilio Cliente", 5, y);
        y += 35;
        context.fillText(cliente.localidad.nombreLocalidad || "Localidad Cliente", 5, y);
        context.fillText(cliente.localidad.provincia.nombreProvincia || "Provincia Cliente", 325, y);
        y += 35;
        context.fillText(cliente.condicionVenta.descripcionCondicionVenta || "Condicion Venta Cliente", 5, y);
        y += 35;
      } else {
        y += 35;
        context.fillText("CONSUMIDOR FINAL", 5, y);
        y += 70;
        context.fillText("CONTADO", 5, y);
        y += 35;
      }

      context.font = "bold 25px Arial";
      y += 35;
      context.fillText(estacionData.data.leyendaConsignados1, 5, y);
      context.fillText(estacionData.data.leyendaConsignados2, 310, y);
      y += 50;
      context.fillText("Descripcion", 5, y);
      y += 35;
      context.fillText("Subtotal", 440, y);
      y += 10;
      context.moveTo(1, y);
      context.lineTo(10000, y);
      context.stroke();
      y += 30;

      if (facturaInfo.articulos) {
        context.font = "28px Arial";
        facturaInfo.articulos.forEach(articulo => {
          context.fillText(articulo.descripcion, 5, y);
          y += 40;
          context.fillText(articulo.cantidadArticulo.toFixed(2), 5, y);

          context.fillText((articulo.importe.toFixed(2) / 1.21).toFixed(2), 430, y);
          y += 40;
        });
      } else {
        console.log("facturaInfo o facturaInfo.articulos es nulo o no está definido.");
      }

      if (facturaInfo.despachos) {
        context.font = "28px Arial";
        facturaInfo.despachos.forEach(despacho => {
          context.fillText(despacho.descripcionArticulo, 5, y);
          y += 40;
          context.fillText(despacho.cantidad.toFixed(2), 5, y);
          context.fillText(despacho.importe.toFixed(2), 430, y);
          y += 40;
        });
      } else {
        console.log("facturaInfo o facturaInfo.despachos es nulo o no está definido.");
      }

      y += 40;

      context.font = "bold 28px Arial";
      context.fillText("Total", 420, y);
      y += 30;
      context.font = "28px Arial";
      context.fillText(totalProductos.toFixed(2), 440, y);
      y += 40;

      context.font = "bold 28px Arial";
      context.fillText("Empleado Responsable", 5, y);
      context.font = "28px Arial";
      context.fillText(user, 350, y);
      y += 40;
      context.font = "bold 26px Arial";
      context.fillText("CAE:", 5, y);
      context.font = "26px Arial";
      context.fillText(facturaFinal.data.CAENumero, 70, y);
      context.font = "bold 26px Arial";
      context.fillText("Vto. CAE:", 280, y);
      context.font = "26px Arial";
      const fechaVencimiento = new Date(facturaFinal.data.CAEFechaVencimiento);
      const diaVencimiento = fechaVencimiento.getDate().toString().padStart(2, "0");
      const mesVencimiento = (fechaVencimiento.getMonth() + 1).toString().padStart(2, "0");
      const anioVencimiento = fechaVencimiento.getFullYear();
      const fechaVencimientoFormateada = `${diaVencimiento}-${mesVencimiento}-${anioVencimiento}`;

      // Luego, utiliza la fecha formateada en el contexto
      context.fillText(fechaVencimientoFormateada, 405, y);
      y += 30;

      const qrJSON = {
        ver: 1,
        fecha: formattedDate,
        // "cuit": estacionData.data.claveTributaria,
        cuit: "20106367117",
        ptoVta: facturaFinal.data.Prefijo + facturaFinal.data.Numero,
        tipoCmp: 1, //arreglar esto
        nroCmp: 94, //q onda esto??
        importe: totalProductos,
        moneda: "ARS",
        ctz: 65,
        tipoDocRec: 80,
        nroDocRec: 20000000001,
        tipoCodAut: "E",
        codAut: 70417054367476,
      };

      const datosBase64 = btoa(JSON.stringify(qrJSON));

      const urlBase = "https://www.afip.gob.ar/fe/qr/";
      const urlQR = `${urlBase}?p=${datosBase64}`;

      const qrImage = await generateQRCodeAsImage(urlQR, 500);

      if (qrImage) {
        context.drawImage(qrImage, 30, y);
      }

      // Para convertir el contenido del canvas en una imagen PNG:
      const dataURL = canvas.toDataURL("image/png");
      const base64Data = dataURL.replace(/^data:image\/png;base64,/, "");

      console.log(base64Data);

      if (!(window.location.hostname === "localhost" && window.location.port === "3000")) {
        window.MyInterface.imprimirFacturaAfip(base64Data);
      }
      setPrinting(true);
      localStorage.setItem("comingFromFinalizarPago", "true");

      // En el componente donde necesitas verificar
      const isComingFromFinalizarPago = localStorage.getItem("comingFromFinalizarPago") === "true";
      localStorage.removeItem("comingFromFinalizarPago"); // Limpiar después de usar si es necesario

      console.log(isComingFromFinalizarPago);
      // Usar el tiempo de espera apropiado según la condición
      const timeoutDuration = isComingFromFinalizarPago ? 2000 : 1;

      setTimeout(() => {
        navigate("/finalizarPago");
      }, timeoutDuration);
    }
  };

  return (
    <div>
      <Box className="printing-container" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress sx={{ color: theme.bandera.colorSecundario }} size={50} />
        <Typography variant="body1" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Imprimiendo comprobante...
        </Typography>
      </Box>
    </div>
  );
}

async function generateQRCodeAsImage(data, size) {
  try {
    const url = await QRCode.toDataURL(data, { width: size });
    const image = new Image();
    image.src = url;
    return new Promise(resolve => {
      image.onload = () => {
        resolve(image);
      };
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default FacturaBExento;
