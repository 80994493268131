import axios from "axios";

const getCategoriasIVA = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/CategoriasIVA/GetCategoriasIVA`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const categoriasIVA = {
  getCategoriasIVA,
};

export default categoriasIVA;
