import axios from "axios";

const getCondVenta = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/CondicionesVenta/GetCondicionesVenta`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const condicionesVenta = {
  getCondVenta,
};

export default condicionesVenta;
