import axios from "axios";

const getQRs = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/MercadoPagoNubeModule/ObtenerListaQRPorEstacion`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const qrs = {
  getQRs,
};

export default qrs;
