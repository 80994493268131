import "./App.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PagoConMercadopago from "./pages/PagoConMercadopago";
import TicketCanvas from "./components/GenerateImage";
import FacturaB from "./components/FacturaB";
import FacturaBExento from "./components/FacturaBExento";
import FacturaA from "./components/FacturaA";
import FinalizarPago from "./pages/FinalizarPago";
import SignaturePadComponent from "./components/SignaturePadComponent";
import Remito from "./components/Remito";
import ClientesSearchPage from "./pages/ClientesSearchPage";
import ArticulosSearchPage from "./pages/ArticulosSearchPage";
import DespachosSearchPage from "./pages/DespachosSearchPage";
import FormasDePagoPage from "./pages/FormasDePagoPage";
import CuotasPage from "./pages/CuotasPage";
import ContraPage from "./pages/ContraPage";
import DialogoImprimirFacturaPage from "./pages/DialogoImprimirFacturaPage";
import AltaCliente from "./pages/AltaCliente";
import { PagoQRPage } from "./pages/PagoQRPage";
import CierreTurnoPage from "./pages/CierreTurnoPage";
import ListadoQRPage from "./pages/ListadoQRPage";
import DashboardMini from "./pages/DashboardMini";
import ChoferesPage from "./pages/ChoferesPage";
import DatosChofer from "./pages/DatosChofer";
import DespachoManualPage from "./pages/DespachoManualPage";
import TransferenciaPage from "./pages/TransferenciaPage";
import ConfiguracionesPage from "./pages/ConfiguracionesPage";

function App() {
  const navigate = useNavigate();

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const isUserLogged = localStorage.getItem("token");
    if (isUserLogged == null) {
      navigate("/");
      setIsLogged(false);
    } else if (window.location.pathname === "/") {
      // Solo navegar al login si no estamos ya en la página de inicio
      navigate("/home");
      setIsLogged(true);
    } else {
      setIsLogged(true);
    }
  }, [navigate]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        <Routes>
          {isLogged ? (
            <>
              <Route element={<Home />} path="/home" />
              <Route element={<ContraPage />} path="/contrapage" />
              <Route element={<Dashboard />} path="/dashboard" />
              <Route element={<ClientesSearchPage />} path="/clientessearchpage" />
              <Route element={<ArticulosSearchPage />} path="/articulossearchpage" />
              <Route element={<DespachosSearchPage />} path="/despachossearchpage" />
              <Route element={<FormasDePagoPage />} path="/formasdepagopage" />
              <Route element={<ContraPage />} path="/contrapage" />
              <Route element={<CuotasPage />} path="/cuotaspage" />
              <Route element={<DialogoImprimirFacturaPage />} path="/dialogoimprimirfacturapage" />
              <Route element={<TicketCanvas />} path="/ticket" />
              <Route element={<FacturaB />} path="/facturaB" />
              <Route element={<FacturaBExento />} path="/facturaBExento" />
              <Route element={<FacturaA />} path="/facturaA" />
              <Route element={<Remito />} path="/remito" />
              <Route element={<PagoConMercadopago />} path="/pagoConMercadopago" />
              <Route element={<FinalizarPago />} path="/finalizarPago" />
              <Route element={<SignaturePadComponent />} path="/firma" />
              <Route element={<PagoQRPage />} path="/pagoqrpage" />
              <Route element={<AltaCliente />} path="/altacliente" />
              <Route element={<CierreTurnoPage />} path="/CierreTurnoPage" />
              <Route element={<ListadoQRPage />} path="/listadoqrpage" />
              <Route element={<DashboardMini />} path="/dashboardmini" />
              <Route element={<ChoferesPage />} path="/choferesPage" />
              <Route element={<DatosChofer />} path="/datoschofer" />
              <Route element={<DespachoManualPage />} path="/despachomanualpage" />
              <Route element={<TransferenciaPage />} path="/transferenciapage" />
              <Route element={<ConfiguracionesPage />} path="/configuracionespage" />
            </>
          ) : (
            <Route element={<Login />} path="/" />
          )}
        </Routes>
      </div>
    </>
  );
}

export default App;
