import axios from "axios";

const getCombustibles = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const idCaja = localStorage.getItem("idCaja");

  const userData = {
    nic: formattedNic,
    token: token,
    idCaja: +idCaja,
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Articulos/GetArticulosCombustible`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const combustibles = {
  getCombustibles,
};

export default combustibles;
