import axios from "axios";

const getIdTalonarioQR = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
  };
  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Terminales/GetTerminalQR`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

const talonarioQR = {
  getIdTalonarioQR,
};

export default talonarioQR;
