const initialState = {
  articulosClickeados: [], // Almacena los artículos clickeados
  contadorGlobal: {}, // Agrega el contador global aquí
};

const articulosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENTAR_ARTICULO":
      // Verifica si el artículo ya existe en el array de articulosClickeados
      const existingArticulo = state.articulosClickeados.find(
        (articulo) => articulo.idArticulo === action.payload.idArticulo
      );

      if (existingArticulo) {
        // Si el artículo ya existe, actualiza su cantidadArticulo
        return {
          articulosClickeados: state.articulosClickeados.map((articulo) =>
            articulo.idArticulo === action.payload.idArticulo
              ? {
                  ...articulo,
                  cantidadArticulo: articulo.cantidadArticulo + 1,
                  importe: articulo.cantidadArticulo * articulo.precioPublico
                }
              : articulo
          ),
        };
      } else {
        // Si el artículo no existe, agrégalo al array con cantidadArticulo 1
        return {
          articulosClickeados: [
            ...state.articulosClickeados,
            {
              ...action.payload, // Agrega el artículo completo
              cantidadArticulo: 1, // Inicializa la cantidadArticulo en 1
              importe: action.payload.precioPublico

            },
          ],
        };
      }

    case "DECREMENTAR_ARTICULO":
      const existeArticuloDecrementar = state.articulosClickeados.find(
        (articulo) => articulo.idArticulo === action.payload.idArticulo
      );

      if (existeArticuloDecrementar) {
        const updatedArticulos = state.articulosClickeados.map((articulo) =>
          articulo.idArticulo === action.payload.idArticulo
            ? {
                ...articulo,
                cantidadArticulo: articulo.cantidadArticulo - 1,
              }
            : articulo
        );

        // Filtra los artículos con cantidadArticulo mayor que 0 antes de actualizar el estado
        const articulosFiltrados = updatedArticulos.filter(
          (articulo) => articulo.cantidadArticulo > 0
        );

        return {
          articulosClickeados: articulosFiltrados,
        };
      } else {
        // Si el artículo no existe, simplemente devuelve el estado actual
        return state;
      }

    case "ACTUALIZAR_ARTICULO":
      return {
        ...state,
        articulosClickeados: action.payload, // Actualiza el estado con la nueva copia de despachos
      };

    case "REINICIAR_ARTICULOS":
      return {
        ...state,
        articulosClickeados: [],
      };

    default:
      return state;
  }
};

export default articulosReducer;
