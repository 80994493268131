import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, CircularProgress, Alert, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FiAlertTriangle } from "react-icons/fi";
import { FiAlertCircle } from "react-icons/fi";

import passEmpleado from "../api/empleado";
import MenuSection from "../components/MenuSection";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { LiaUserCheckSolid } from "react-icons/lia";

const ContraPage = () => {
  const [password, setPassword] = useState("");
  const [empleado, setEmpleado] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [contentVisible, setContentVisible] = useState(false);
  const [imprimirModalOpen, setImprimirModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para controlar la visibilidad del spinner
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const verificarEmpleado = async () => {
      setLoading(true);
      setContentVisible(false);
      try {
        const resultado = await passEmpleado.getEmpleado();
        setEmpleado(resultado.data);
        setContentVisible(true);
      } catch (error) {
        setError(true);
        handleAlertOpen(<span>Error al obtener los empleados, se actualizará la pagina</span>);
        setTimeout(() => {
          handleAlertClose();
          verificarEmpleado(); // Reintentar la obtención de datos después de 3 segundos
        }, 3000);

        console.error("Error al obtener los empleados:", error);
      } finally {
        setLoading(false);
      }
    };
    verificarEmpleado();
  }, []);

  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    // Función para manejar el evento cuando se presiona el botón de retroceso
    // Aquí puedes redirigir a otra página o simplemente hacer nada
    navigate("/contrapage"); // Redirecciona a otra página según sea necesario
  };

  const handleAlertOpen = (message, severity) => {
    setAlertMessage(message);
    setAlertOpen(true);
    setContentVisible(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setImprimirModalOpen(true);
    setContentVisible(true);
  };

  const handleInvalida = () => {
    setAlertOpen(false);
    setContentVisible(true);
  };

  const handlePasswordChange = async event => {
    const text = event.target.value;

    if (!text) {
      setPassword("");
      return;
    }

    async function hashWithSHA1ToBase64UTF16(text) {
      const data = new Uint16Array(text.length);
      for (let i = 0; i < text.length; i++) {
        data[i] = text.charCodeAt(i);
      }

      const buffer = data.buffer;
      const hashBuffer = await crypto.subtle.digest("SHA-1", buffer);
      const hashBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));
      return hashBase64;
    }

    hashWithSHA1ToBase64UTF16(text).then(hash => setPassword(hash));
    // console.log(password);
  };

  const handleClose = () => {
    setLoading(true); // Activar el spinner cuando se hace clic en el botón "Verificar"
    const empleadoEncontrado = empleado && empleado.find(emp => emp.password.toLowerCase() === password.toLowerCase());
    if (empleadoEncontrado) {
      localStorage.setItem("idEmpleadoVendedor", empleadoEncontrado.idEmpleado);
      handleAlertOpen(
        <span>
          Verificación exitosa, empleado: <strong>{empleadoEncontrado.nombreEmpleado}</strong>
        </span>,
      );
      localStorage.setItem("empleadoVendedor", empleadoEncontrado.nombreEmpleado);
      localStorage.setItem("idEmpleadoResponsable", empleadoEncontrado.idEmpleado);
      setIsValid(true); // Establece isValid a true si la contraseña es válida
      setTimeout(() => {
        setLoading(false); // Desactivar el spinner antes de navegar a la siguiente página
        if (localStorage.getItem("vengoDeTransferencia") == "true") {
          navigate("/home");
        } else {
          navigate("/dialogoimprimirfacturapage"); // Navega a "/ticket" después de 3 segundos
        }
      }, 1500);
    } else {
      setLoading(false); // Desactivar el spinner si la contraseña es inválida
      handleAlertOpen("Contraseña inválida");
      setIsValid(false); // Establece isValid a false si la contraseña es inválida
      setTimeout(() => {
        setAlertOpen(false);
        setContentVisible(true);
      }, 1500);
    }
  };

  const handleAlertOpenWithAction = (message, severity) => {
    setAlertMessage(message);
    setAlertOpen(true);
    setContentVisible(false);
  };
  const theme = useTheme();
  return (
    <>
      <MenuSection />
      {loading && (
        <CircularProgress sx={{ color: theme.bandera.colorSecundario, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
      )}
      {contentVisible && (
        <Box
          sx={{
            position: "absolute",
            top: "46%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "50px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: window.innerWidth > 700 ? "80%" : "87%",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 4 }}>
              Por favor ingrese su contraseña:
            </Typography>
            <TextField type="password" id="password-input" label="CONTRASEÑA" variant="outlined" onChange={handlePasswordChange} />
          </Box>
          <Box sx={{ marginTop: 3.5, display: "flex", marginLeft: window.innerWidth > 700 ? "0px" : "80px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
              }}
              onClick={handleClose}
            >
              <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                VERIFICAR{" "}
              </Typography>
            </Button>
          </Box>
          {loading && <CircularProgress sx={{ mt: 2, color: theme.bandera.colorSecundario }} />} {/* Mostrar el spinner si loading es true */}
        </Box>
      )}

      {alertOpen && (
        <Alert
          severity={error ? "error" : isValid ? "success" : "warning"} // Use error for error state
          icon={
            error ? (
              <FiAlertTriangle style={{ fontSize: "4rem" }} />
            ) : isValid ? (
              <LiaUserCheckSolid style={{ fontSize: "4rem" }} />
            ) : (
              <FiAlertCircle style={{ fontSize: "4rem" }} />
            )
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiAlert-message": {
              fontSize: "1.25rem",
              textAlign: "center",
            },
            position: "fixed",
            top: "20%", // Más alto que antes, ajusta según sea necesario
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%", // Asegúrate de que el ancho sea responsivo
            maxWidth: 400,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleAlertClose} // Asegúrate de tener esta función definida correctamente
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: "25px",
              }}
            >
              <HighlightOffIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
};

export default ContraPage;
