import axios from "axios";

const getPercepciones = async (clienteStr) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    cliente: clienteStr, // Add clienteStr to userData
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Clientes/ClientePagaPercepciones`, userData);
    return response.data; // Ensure to return the actual data
  } catch (error) {
    throw error;
  }
};

const percepciones = {
  getPercepciones,
};

export default percepciones;
