import axios from "axios";

const getChoferes = async searchTerm => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  const userData = { nic: formattedNic, token: token, pageNumber: 1, searchString: searchTerm, idCliente: cliente.idCliente };
  console.log(userData);

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/choferes/getchoferes`, userData);
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

const choferes = {
  getChoferes,
};

export default choferes;
