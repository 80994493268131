import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Button, useMediaQuery } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import LogoCaldenOil from "../images/logo-caldenoil.png";
import FacturacionForm from "../components/FacturacionForm";
import { useTheme } from "@mui/material/styles";

const Dashboard = () => {
  const location = useLocation(); // Get the location object
  const [showDespachoModal, setShowDespachoModal] = useState(false);
  const [showArticuloModal, setShowArticuloModal] = useState(false);
  const [showClienteModal, setShowClienteModal] = useState(false);
  const navigate = useNavigate();
  const isScreenWidthLessThan600 = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const handleClick = () => {
    localStorage.setItem("cliente", "");

    navigate("/home");
  };
  const pathname = location.pathname; // Aquí reemplaza con tu pathname real
  const parts = pathname.split("/");
  const despachoId = parts[2]; // El número estará en la posición 2 después de dividir

  return (
    <Grid container spacing={2} style={{ height: window.innerWidth < 700 ? "88vh" : "76.5vh" }}>
      {!isScreenWidthLessThan600 ? (
        <Grid item xs={12} md={isScreenWidthLessThan600 ? 1.5 : 2.5} justifyContent="center" textAlign="right">
          <Button
            onClick={handleClick}
            sx={{
              color: "#0E58A7",
              fontWeight: "bold",
              marginTop: isScreenWidthLessThan600 ? "50rem" : "0rem",
            }}
          ></Button>
        </Grid>
      ) : (
        <Grid item xs={12} justifyContent="center" textAlign="left"></Grid>
      )}

      {/* Segunda columna */}
      <Grid item xs={12} md={isScreenWidthLessThan600 ? 5 : 6.5}>
        <Box display="flex" justifyContent={isScreenWidthLessThan600 ? "center" : "space-around"}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              borderRadius: 1,
              fontWeight: "bold",
              fontSize: 13,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={() => navigate("/despachossearchpage")}
          >
            <LocalGasStationIcon />
            DESPACHO
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              borderRadius: 1,
              fontWeight: "bold",
              fontSize: 13,
              marginLeft: 0.5,
              marginRight: 0.5,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={() => navigate("/articulossearchpage")}
          >
            <SearchIcon />
            ARTICULO
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              borderRadius: 1,
              fontWeight: "bold",
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
              fontSize: 13,
            }}
            onClick={() => navigate("/clientessearchpage")}
          >
            <PersonIcon />
            CLIENTE
          </Button>
        </Box>
        <FacturacionForm despachoId={despachoId} />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
