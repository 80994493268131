// helpers/pagoHelpers.js
import estadoDespacho from "../api/estadoDespacho";
import { CircularProgress } from "@mui/material";

export const actualizarDespachos = async () => {
  const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
  let allUpdatesSuccessful = true;

  for (let idDespacho of despachosArray) {
    try {
      await estadoDespacho.actualizarDespacho(idDespacho, 1, 1);
    } catch (error) {
      console.error("Error al actualizar el despacho:", error);
      allUpdatesSuccessful = false;
    }
  }

  if (allUpdatesSuccessful) {
    localStorage.removeItem("despachosSeleccionados");
  }
};

export const handlePagoEvent = (setIsLoading, navigate) => {
  return async event => {
    const responsePagoConTarjetaJava = event.detail.success;
    const jsonString = JSON.stringify(event.detail.responsePagoConTarjeta);

    localStorage.setItem("CuponClover", jsonString);
    localStorage.setItem("responsePagoConTarjetaJava", responsePagoConTarjetaJava);

    if (responsePagoConTarjetaJava) {
      setIsLoading(true);
      await actualizarDespachos();
      await setTarjetaID(jsonString, JSON.parse(localStorage.getItem("tarjetas")));
      navigate("/contrapage");
    }
  };
};

export const setTarjetaID = async (jsonString, tarjetasData) => {
  const nic = localStorage.getItem("formattedNic");
  const CuponClover = JSON.parse(jsonString);
  const cardTypeLabel = CuponClover ? CuponClover.payment.transactionInfo?.cardTypeLabel || CuponClover.payment.tender.label || "" : "";

  tarjetasData.forEach(tarjeta => {
    console.log(tarjeta);

    const tarjetaNombre = tarjeta.nombre ? tarjeta.nombre.toUpperCase() : "";
    const pagoElectronico = tarjeta.pagoElectronico ? tarjeta.pagoElectronico : false;
    const cardTypeUpper = cardTypeLabel.toUpperCase();

    if (nic != "http://comercialbb.caldenoil.com:5001") {
      if ((tarjetaNombre.includes("QR") && cardTypeUpper.includes("QR")) || tarjetaNombre === cardTypeUpper) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "MASTER DEBIT" && cardTypeUpper.includes("MC DEBIT")) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "MASTERCARD" && cardTypeUpper.includes("MC")) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      }
    } else {
      if (tarjetaNombre.includes("QR") && cardTypeUpper.includes("QR")) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "MASTER DEBIT" && cardTypeUpper.includes("MC DEBIT")) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "MASTERCARD" && cardTypeUpper.includes("MC")) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "VISA" && cardTypeUpper === "VISA DEBITO") {
        console.log(tarjeta.idTarjeta);
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "VISA CREDITO" && cardTypeUpper === "VISA") {
        console.log(tarjeta.idTarjeta);
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === "AMERICAN EXPRESS AX" && cardTypeUpper === "AMEX") {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      } else if (tarjetaNombre === cardTypeUpper) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
      }
    }

    if (localStorage.getItem("vengoDeQR") == "true") {
      if (tarjetaNombre.includes("YPF") && pagoElectronico) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        localStorage.setItem("vengoDeQR", false);
      } else if (tarjetaNombre.includes("SHELL") && pagoElectronico) {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        localStorage.setItem("vengoDeQR", false);
      }
    }
  });

  return localStorage.getItem("idTarjeta");
};

export const showLoadingSpinner = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    }}
  >
    <CircularProgress />
  </div>
);
