import axios from "axios";

const getLimiteAfip = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/ObtenerLimiteFacturaBSinDNI`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const limiteAfip = {
  getLimiteAfip,
};

export default limiteAfip;
