import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton, useMediaQuery, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";

const DialogoImprimirFacturaPage = () => {
  const navigate = useNavigate();
  const isScreenWidthLessThan600 = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [confirmOpen, setConfirmOpen] = useState(false);
  const mandarAImprimir = () => {
    const facturaInfoString = localStorage.getItem("facturaInfo");
    const facturaInfo = JSON.parse(facturaInfoString);
    const imprimir = true;
    navigate("/ticket/", { state: { facturaInfo, imprimir } });
    localStorage.setItem("limpiarArticulos", false);
  };

  const sinImpresion = () => {
    const facturaInfoString = localStorage.getItem("facturaInfo");
    const facturaInfo = JSON.parse(facturaInfoString);
    const imprimir = false;
    navigate("/ticket/", { state: { facturaInfo, imprimir } });
    localStorage.setItem("limpiarArticulos", false);
  };
  const theme = useTheme();
  return (
    <>
    <MenuSection/>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: isScreenWidthLessThan600 ? "95%" : "100%",
          height: isScreenWidthLessThan600 ? "70%" : "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={sinImpresion}
        >
          <CloseIcon />
        </IconButton>
        <h3
          style={{
            marginTop: isScreenWidthLessThan600 ? "60px" : "160px",
            backgroundColor: theme.bandera.colorSecundario,
            color: "white",
            fontWeight: "bold",
            padding: "20px 70px",
            borderRadius: "4px",
            width: isScreenWidthLessThan600 ? "90%" : "100%",
            textAlign: "center",
            whiteSpace: isScreenWidthLessThan600 ? "normal" : "nowrap",
          }}
        >
          ¿Quiere imprimir el comprobante?
        </h3>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "40px",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Box>
            <Button
              variant="contained"
              onClick={mandarAImprimir}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 20,
                width: 130,
                height: 110,
                flexDirection: "column",
              }}
            >
              SI
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={sinImpresion}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 20,
                width: 130,
                height: 110,
                flexDirection: "column",
              }}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DialogoImprimirFacturaPage;
