import axios from "axios";

const postActualizarFirma = async cierreDeTurno => {
  const token = localStorage.getItem("token");
  const firma = localStorage.getItem("firma");
  const idMovimientoFac = localStorage.getItem("idMovimientoFac");
  const idMovimientoFacNumber = Number(idMovimientoFac.replace(/[\[\]]/g, ""));
  const nic = localStorage.getItem("formattedNic");
  console.log(idMovimientoFacNumber);

  try {
    const response = await axios.post(
      "https://facturador-proxy.azurewebsites.net/api/Facturacion/ActualizarFirmaRemito",
      {
        nic,
        token,
        idMovimientoFac: idMovimientoFacNumber,
        firma,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      console.log("PDF firmado y enviado con éxito:", response.data);
      return true;
    } else {
      console.error("Error al enviar el PDF firmado:", response.data);
      return false;
    }
  } catch (error) {
    console.error("Error en la llamada al endpoint:", error);
    return false;
  }
};

const actualizarFirma = {
  postActualizarFirma,
};

export default actualizarFirma;
