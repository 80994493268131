import axios from "axios";

const getClientes = async searchTerm => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const userData = { nic: formattedNic, token: token, pageNumber: 1, searchString: searchTerm };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Clientes/GetClientes`, userData);
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
};

const clientes = {
  getClientes,
};

export default clientes;
